const user = JSON.parse(localStorage.getItem('user'));

export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    login({ commit }, user) {
        commit('login', user);
    },
    logout({ commit }) {
        commit('logout');
    },
};

export const getters = {
    loggedIn: (state) => {
        return !!state.status.loggeduser;
    },
    currentUser: (state) => {
        return state.user;
    },
};

export const mutations = {
    login(state, user) {
        localStorage.setItem('user', JSON.stringify(user));
        state.status = { loggeduser: true };
        state.user = user;
    },
    logout(state) {
        localStorage.removeItem('user');
        state.status = {};
        state.user = null;
    },
};

