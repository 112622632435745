import api from '@/helpers/axios.service';

export const apiMethods = {
    // eslint-disable-next-line
    async db_query(request) {
        try {
            const request_body = {
                sql: request.sql,
                input: request.input,
            };
            const response = await api.post('/db-query', request_body);
            if (response.status == 200) {
                return response;
            } else {
                return {
                    error: true,
                    message: 'Error in query[1]',
                }
            }
        } catch (error) {
            return {
                error: true,
                message: 'Error in query[2]',
            }
        }
        
    },
    // eslint-disable-next-line
    async db_post(request) {
        try {
            const request_body = {
                sql: request.sql,
                input: request.input,
            };  
            const response = await api.post('/db-post', request_body);
            if (response.status == 200) {
                return response;
            } else {
                return {
                    error: true,
                    message: 'Error in post[1]',
                }
            }
        } catch (error) {
            console.log(error);
            return {
                error: true,
                message: 'Error in post[2]',
            }
        }
    },
};

